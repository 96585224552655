<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      color="light-blue"
      icon="mdi-account-tie"
      title="Contactos"
      class="px-5 py-3"
    >
      <v-data-table
        v-if="contactoslist"
        :headers="headers"
        :items="contactos"
        :search="search"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="primary"
              fab
              dark
              small
              class="mb-2"
              @click="addContacts()"
            >
              <v-icon
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.nombre_apellido }}</td>
            <td>{{ item.empresa }}</td>
            <td>{{ item.telefono }}</td>
            <td>{{ item.movil }}</td>
            <td>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <addContactos
        :edited-item="datos"
        :edited-index="editedIndex"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import addContactos from './addContact.vue'
  export default {
    name: 'ContactosTable',
    components: {
      addContactos,
    },
    data: vm => {
      return {
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        datos: {},
        editedIndex: -1,
        headers: [
          {
            text: 'Nombre y Apellidos',
            align: 'left',
            sortable: true,
            value: 'nombre_apellido',
          },
          {
            text: 'Empresa',
            align: 'left',
            sortable: true,
            value: 'empresa',
          },
          {
            text: 'Teléfono',
            align: 'left',
            sortable: true,
            value: 'telefono',
          },
          {
            text: 'Movil',
            align: 'left',
            sortable: true,
            value: 'movil',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      contactoslist: {
        get () {
          return (this.$store.state.contactos.contactoslist)
        },
      },
      contactos: {
        get () {
          return this.$store.state.contactos.contactos
        },
        set (value) {
          return this.$store.commit('contactos/setContactos', value)
        },
      },
      loading: {
        get () {
          return this.$store.state.contactos.loading
        },
      },
    },
    mounted () {
      this.$store.dispatch('contactos/getContactos')
    },
    methods: {
      addContacts () {
        this.datos = {}
        this.editedIndex = -1
        this.$store.commit('contactos/setRegister', true)
      },
      editItem (item) {
        this.editedIndex = this.contactos.indexOf(item)
        this.datos = Object.assign({}, item)
        this.$store.commit('contactos/setRegister', true)
      },
      deleteItem (item) {
        this.$confirm('¿Está seguro que desea eliminar el registro?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('contactos/deleteContactos', item.id)
                .then((response) => {
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
