<template>
  <v-card v-if="contactosadd">
    <template>
      <!-- <v-subheader>Datos Personales</v-subheader> -->
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <input
            v-model="editedItem.id"
            type="hidden"
          >
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.nombre_apellido"
                label="Nombre y Apellido"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.empresa"
                label="Empresa"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <vue-tel-input-vuetify
                v-model="editedItem.telefono"
                attach
                solo
                label="Teléfono Fijo"
                placeholder="Teléfono"
                valid-characters-only
                mode="international"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <vue-tel-input-vuetify
                v-model="editedItem.movil"
                attach
                solo
                label="Móvil"
                placeholder="Móvil"
                valid-characters-only
                mode="international"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.direccion"
                label="Dirección"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.postal"
                label="Código Postal"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-text-field
                v-model="editedItem.poblacion"
                label="Población"
              />
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="8"
            >
              <v-text-field
                v-model="editedItem.observaciones"
                label="Observaciones"
                placeholder="Observaciones"
                valid-characters-only
                mode="international"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-spacer />
              <v-btn
                color="#F6F6F6"
                style="color:black;"
                @click="volver"
              >
                {{ $t("close") }}
              </v-btn>
              <v-btn
                class="mr-4"
                color="primary"
                :loading="loading"
                @click="submit"
              >
                {{ $t("save") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-container>
    </template>
  </v-card>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'AddContactos',
    props: {
      editedItem: {
        type: Object,
        required: true,
      },
      editedIndex: {
        type: Number,
        default: 0,
      },
    },
    data: vm => {
      return {
        tab: null,
        valid: true,
        dateFn: new Date().toISOString().substr(0, 10),
        menuFn: false,
        search: '',
        reset: false,
        emailRules: [
          v => !!v || 'El correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        /* editedItem: {
          id: 0,
          nombre_apellido: '',
          empresa: '',
          telefono: '',
          movil: '',
          direccion: '',
          postal: '',
          poblacion: '',
          observaciones: '',
        }, */
      }
    },
    computed: {
      contactosadd: {
        get () {
          return this.$store.state.contactos.contactosadd
        },
      },
      contactos: {
        get () {
          return this.$store.state.contactos.contactos
        },
      },
      loading: {
        get () {
          return this.$store.state.contactos.loading
        },
      },
    },
    watch: {
      dateFn (val) {
        this.editedItem.fecha = this.formatDate(this.dateFn)
      },
    },
    async beforeMount () {
      await this.$store.commit('contactos/setRegister', false)
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      volver () {
        this.editedIndex = -1
        this.$refs.form.reset()
        this.$store.commit('contactos/setRegister', false)
      },
      submit () {
        if (this.$refs.form.validate()) {
          const data = {
            index: this.editedIndex,
            id: this.editedItem.id,
            nombre_apellido: this.editedItem.nombre_apellido,
            empresa: this.editedItem.empresa,
            direccion: this.editedItem.direccion,
            email: this.editedItem.email,
            telefono: this.editedItem.telefono,
            movil: this.editedItem.movil,
            fecha: this.editedItem.fecha,
            postal: this.editedItem.postal,
            poblacion: this.editedItem.poblacion,
            observaciones: this.editedItem.observaciones,
          }
          // console.log(data)
          if (this.editedIndex < 0) {
            this.$store.dispatch('contactos/addContactos', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          } else {
            this.$store.dispatch('contactos/updateContactos', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                if (response.data.email) {
                  setTimeout(() => {
                    this.$store.dispatch('logout')
                  }, 3000)
                }
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        }
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  background-color: aliceblue;
}
.expansionPanel {
  width: 100%;
}
.vue-tel-input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #bbb;
}
</style>
